'use client'

import React, { FC, useState } from 'react'
import dynamic from 'next/dynamic'
import ActionButton from '@/components/buttons/ActionButton'
import { Main } from '@/components/common'
const Scanner = dynamic(() => import('../../components/ScanCode'))
// TODO this is not yet used, but can be used for partners with applessOnly enabled if needed.
const Home: FC = () => {
  const [showQr, setShowQr] = useState(false)

  return (
    <Main className="justify-center flex flex-1 flex-col gap-12">
      {showQr ? (
        <>
          <h1 className="text-white text-center prose-header-md">
            Scan the QR-code
          </h1>
          <Scanner />
          <p className="text-white text-center prose-body-md">
            Find a Brick station in your area and scan the QR-code.
          </p>
        </>
      ) : (
        <>
          <h1 className="text-white text-center prose-header-md">
            Unfortunately, our app isn&apos;t available for download just yet
          </h1>
          <p className="text-white text-center prose-body-md">
            Our web-based QR scanner is here to help! The app is coming soon,
            but for now, you can scan any QR code from your browser. Find
            physical Brick stations in your area to discover more. Thank you for
            your interest in our app, and stay tuned for updates on our
            progress.
          </p>
          <ActionButton
            id="scan-station"
            variant="primary"
            onClick={() => setShowQr((prev) => !prev)}
            style={{
              visibility: showQr ? 'hidden' : 'visible',
            }}
          >
            Scan station
          </ActionButton>
        </>
      )}
    </Main>
  )
}

export default Home
