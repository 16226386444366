import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

export const Main: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
> = ({ children, className, ...props }) => (
  <main
    id="main"
    className={cn([
      'max-w-screen-xl w-full flex flex-1 flex-col px-6 gap-6 pt-4 pb-12 mx-auto bg-black',
      className,
    ])}
    {...props}
  >
    {children}
  </main>
)
