'use client'
import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'

import cn from 'classnames'

import Spinner from '../Spinner'

type Props = {
  loading?: boolean
  disabled?: boolean
  variant?: 'primary' | 'secondary'
  textClassName?: string
  id: string
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const ActionButton: FC<Props> = ({
  children,
  loading,
  disabled,
  className,
  variant = 'primary',
  textClassName,
  id,
  type,
  ...props
}) => {
  return (
    <button
      className={cn([
        'disabled:bg-grey bg-black text-white h-14 flex-shrink-0 outline-none w-full rounded flex items-center justify-center relative',
        variant === 'primary' && 'bg-pink',
        variant === 'secondary' && 'border-2 border-solid border-white',
      ])}
      disabled={loading || disabled}
      id={`${type}_${variant}_${id}`}
      {...props}
    >
      {loading ? (
        <Spinner />
      ) : (
        <span className={cn(['prose-action-md', textClassName])}>
          {children}
        </span>
      )}
    </button>
  )
}

export default ActionButton
